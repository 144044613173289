.step-container {
  .step-circle {
    width: 100%;
    height: 100%;
    border: solid 1px #f4f4f4;
    background-color: $white;
    border-radius: 50%;
    padding: 5px;
  }

  .step-number-container {
    width: 100%;
    height: 100%;
    border: solid 1px $secondary;
    border-radius: 50%;
    background-color: $white;
    color: $secondary;
    font-size: 1.15rem;
  }

  .title-step {
    font-size: 0.9rem;
    color: #838383;
  }

  &.active {
    .step-circle {
      border: solid 1px $secondary;
    }

    .step-number-container {
      background-color: $secondary;
      color: $white;
    }

    .title-step {
      color: $secondary;
    }
  }
}

.step-line {
  position: absolute;
  width: 100%;
  height: 100%;
  border-top: 1px solid #f4f4f4;
  transform: translate(50%, calc(50% - 10px));
  &.ready {
    border-top: 1px solid $secondary;
  }
}
