// styles for react-reactstrap-pagination

.pagination-sm {
  .page-link {
    margin: 0 3px;
    border-radius: 4px;
    border-color: $body-bg;
  }
  .page-item.disabled .page-link {
    color: $color-light;
  }
}
