// File to create new classes or override part of bootstrap classes
html,
body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
}

//  ------- FONT -------
// This font is used for the custom input
@font-face {
  font-family: "fontello";
  src: url("../font/fontello.eot?6082992");
  src: url("../font/fontello.eot?6082992#iefix") format("embedded-opentype"),
    url("../font/fontello.woff2?6082992") format("woff2"),
    url("../font/fontello.woff?6082992") format("woff"),
    url("../font/fontello.ttf?6082992") format("truetype"),
    url("../font/fontello.svg?6082992#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

.line-height-2 {
  line-height: 2;
}

//  ------- AUTH-LAYOUT -------
.container-logo-global {
  height: 5rem;
}

.logo-global {
  height: 2.5rem;
}

// ------- CARD WITHOUT AUTHENTICATION ------
.card-authentication {
  z-index: 10;
  box-shadow: 10px 10px 20px #00000029;
  background-color: transparent;
}

.bg-image-login {
  background-color: #2e2e2e;
}

.login-card {
  border: none;
}
// ------- ELEMENTS FOR DASHBOARD LAYOUT ------
.dashboard-container {
  background-color: #f0f0f0;
}
// MOBILE ONLY
@include media-breakpoint-down(xs) {
  .header-container {
    background-color: $white;
  }

  .icon-bars {
    width: 2rem;
    height: 2rem;
    fill: #000;
  }

  .bg-lateral-menu {
    z-index: 1035;
    background-color: transparentize($color: #000000, $amount: 0.9);
    width: 100vw;
    height: 100vh;
    &.active {
      position: absolute;
    }
    &.inactive {
      display: none;
    }
  }
}

// -- HEADER
.container-icon-user {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-color: $secondary;
  border-radius: 1rem;
}

// -- LATERAL MENU
$sidenavWidth: 12.5rem;
$sidenavWidthCollapsed: 5.3rem;
$sidenavAnimationTime: 0.5s;

.lateral-menu {
  background-color: $white;
  z-index: 1036;
  -webkit-box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 10px 0px 20px 0px rgba(0, 0, 0, 0.08);
  width: $sidenavWidthCollapsed;
  @keyframes slidein {
    from {
      width: $sidenavWidthCollapsed;
    }
    to {
      width: $sidenavWidth;
    }
  }
  @keyframes slideout {
    to {
      width: $sidenavWidthCollapsed;
    }
    from {
      width: $sidenavWidth;
    }
  }
  &.open {
    width: $sidenavWidth;
    animation: slidein $sidenavAnimationTime;
  }
  &.closed {
    width: $sidenavWidthCollapsed;
    animation: slideout $sidenavAnimationTime;
  }
  @include media-breakpoint-down(xs) {
    width: $sidenavWidth;
    transform: translateX(-$sidenavWidth);
    position: absolute;
    @keyframes slidein {
      from {
        transform: translateX(-$sidenavWidth);
      }
      to {
        transform: translateX(0);
      }
    }
    @keyframes slideout {
      to {
        transform: translateX(-$sidenavWidth);
      }
      from {
        transform: translateX(0);
      }
    }
    &.open {
      transform: translateX(0);
    }
    &.closed {
      width: $sidenavWidth;
      transform: translateX(-$sidenavWidth);
    }
  }
}

.menu-item {
  height: 2.9rem;
  border-radius: 5px;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #f0f0f0;
  }
}

.icon-text-container {
  width: calc(100% - 3px);
}

.menu-item-container {
  min-height: 3rem;

  svg.icon-menu {
    width: 20px;
    path {
      fill: $color-light; // color for the menu icon inactive
    }
  }
  .title-menu {
    color: $color-light;
  }

  .border-option {
    width: 3px;
    height: 1.7rem;
    border-radius: 4px;
  }

  &.active {
    svg.icon-menu {
      path {
        fill: $primary; // color for the menu icon active
      }
    }

    .border-option {
      background: $primary;
    }

    .title-menu {
      font-size: 1rem;
      font-weight: 600 !important;
      color: $color-light;
    }
  }
}

a.menu-item-container {
  text-decoration: none;
}

.btn-logout {
  height: 3rem;
  border-radius: 0;
  border-width: 0;
  text-transform: none;
  color: $primary;
  transform: none !important;
  background-color: $white;

  span {
    font-size: 1rem;
    color: $secondary;
  }

  svg path {
    fill: $secondary;
  }

  &:hover,
  &:active {
    background-color: $light !important;
    color: $primary;
  }
}

// ------- BORDER RADIUS ------
.rounded-xl {
  border-radius: 0.6rem !important;
}

.rounded-xxl {
  border-radius: 0.8rem !important;
}

// ------- FONT SIZES ------
.fs-0-9 {
  font-size: 0.9rem;
}

.fs-1-0 {
  font-size: 1.0rem;
}

.fs-1-1 {
  font-size: 1.1rem;
}

.fs-1-15 {
  font-size: 1.15rem;
}

.fs-1-2 {
  font-size: 1.2rem;
}

.fs-1-25 {
  font-size: 1.25rem;
}

.fs-1-3 {
  font-size: 1.3rem;
}

.fs-1-4 {
  font-size: 1.3rem;
}

.fs-1-5 {
  font-size: 1.5rem;
}

.fs-2 {
  font-size: 2rem;
}

// ------- LINKS ------
.primary-link {
  cursor: pointer;
  color: $primary !important;
  &:hover {
    color: $primary !important;
  }
}

// ------- SCROLL ------
.overflow-scroll-hidden {
  overflow-y: scroll;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.overflow-scroll-hidden::-webkit-scrollbar {
  display: none;
}

// ------- TEXT ------
.one-line-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.color-dark {
  color: $color-dark;
}

.color-medium {
  color: $color-medium;
}

.color-light {
  color: $color-light;
}

.text-underline {
  text-decoration: underline;
}

.white-text {
  color: $white !important;
}

//  ------- CURSOR ------
.cursor-pointer {
  cursor: pointer;
}

//  ------- SVG COLOR ------
.svg-white {
  path {
    fill: $white;
  }
  rect {
    fill: $white;
  }
}

.svg-primary {
  path {
    fill: $primary;
  }
  rect {
    fill: $primary;
  }
}

.svg-secondary {
  path {
    fill: $secondary;
  }
  rect {
    fill: $secondary;
  }
}

//  ------- CONTAINERS WITH MAX WIDTH ------
.container-max-250 {
  width: 100%;
  max-width: 250px;
}

.container-max-400 {
  width: 100%;
  max-width: 400px;
}

.container-max-600 {
  width: 100%;
  max-width: 600px;
}

//------- reCAPTCHA ------
.g-recaptcha-center div {
  margin: 0 auto;
}

// ------- SPIN ------
.spin {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Styles for inputs within a table
.input-table {
  background-color: #f4f4f4;
  color: #838383;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 1rem;
  border-radius: 0;

  &:focus {
    color: $secondary;
    outline-color: $secondary;
    border-radius: 0;
  }
}

// ------- TOOLTIP ------
.tooltip-inner {
  background-color: $secondary;
  color: white;
  padding: 10px;
}

.tooltip.show {
  opacity: 1;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: $secondary !important;
}

// ------- FORM-TEXT ------
.form-text {
  padding-left: 5px;
  font-size: 1rem;
}

// ------- RECENT ACCOUNTS COMPONENT ------
.recent-account {
  cursor: pointer;
  border: 1px solid $secondary;
  border-radius: 5px;
  padding: 15px 20px;
  margin-bottom: 10px;
  span:first-child {
    color: $color-dark;
  }
  &:hover {
    background-color: $secondary;
    span {
      color: $white !important;
    }
  }
}

.box-more {
  cursor: pointer;
  border: 1px solid $secondary;
  border-radius: 5px;
  span {
    color: $secondary;
  }
  &:hover {
    background-color: $secondary;
    span {
      color: $white !important;
    }
  }
}

// To make the background blurry when the CustomModal is open
body.modal-open .layout {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
}

.payment-info-container {
  padding: 0.5rem 0.75rem;
}

.bg-color-body {
  background-color: $body-bg;
}

.custom-rounded {
  border-radius: 0.3rem;
}

// PROFILE MENU STYLES
.profile-menu {
  display: flex;
  color: $color-medium;
}

.change-password-btn {
  text-transform: uppercase;
  border: 1px solid $secondary;
  border-radius: 4px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  white-space: nowrap;
  color: $primary;
  font-weight: 600;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  #change-password-padlock {
    svg,
    path {
      fill: $primary;
    }
  }
  #change-password-chevron {
    margin-left: 50px;
    margin-right: 1rem;
    path:nth-child(2) {
      fill: $primary;
    }
  }
}

// CHANGE PASSWORD MODAL STYLES
.change-password-tooltip {
  @include media-breakpoint-up(sm) {
    .custom-input-tooltip {
      right: 60px;
    }
  }
}

// Debt list table

// Show scroll bar always in chrome
div.debt-list::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

div.debt-list::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.2);
}

div.debt-list {
  // Max with capital letter so it uses the CSS max() function
  height: Max(330px, calc(100vh - 540px));
}

// PAYMENT HISTORY
.payment-history-form-inner-container {
  @include media-breakpoint-down(md) {
    display: block;
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  display: flex;
}

.payment-history-input {
  margin-right: 20px !important;
  width: 250px;

  @include media-breakpoint-down(md) {
    width: 100%;
    margin-top: 10px !important;
    margin-right: 0px !important;
  }

  input {
    border: 1px solid $primary;
  }
  .custom-input-component.without-value {
    background-color: transparent !important;
  }
  .form-control {
    &:focus {
      box-shadow: none;
      border: 1px solid $primary;
    }
  }
  svg {
    margin-left: -25px;
    
    path {
      fill: $primary;
    }
  }
}

.payment-history-submit {
  margin-bottom: 30px;

  @include media-breakpoint-down(md) {
    margin-top: 10px !important;
    margin-bottom: 0px;
  }

  button {
    padding: 0;
    height: 3rem;
    min-height: auto;
  }
}

.payment-history-dropdown-toggle {
  display: flex;
  align-items: center;
  background-color: transparent !important;
  color: $primary !important;

  svg {
    &#filter {
      margin-right: 1rem;
      path {
        fill: $primary !important;
      }
    }

    &#payment-history-caret {
      margin-left: auto;
      transform: rotate(90deg);
      fill: $primary !important;
    }
  }
}

.payment-history-dropdown-open {
  svg {
    &#payment-history-caret {
      transform: rotate(-90deg);
    }
  }
}