// Las tablas de global 3000 usan los estilos de bootstrap
// y los estilos sobreescritos en este archivo
// Para utilizarlos agregar la clase table y custom-table al tag <table>
table.table.custom-table {
  thead {
    color: $white;
    th {
      font-weight: 400;
    }
  }

  tbody {
    font-size: 1rem;
    color: #1e1e1e;

    // Always display the table like a table-striped
    tr:nth-of-type(odd) {
      background-color: rgba(247, 247, 247, 0.6);
    }

    .border-hover {
      width: 6px;
      min-width: 6px;
      height: 100%;
      background-color: transparent;
      border-radius: 3px;
      padding: 0px !important;
    }

    tr:hover .border-hover {
      background-color: $secondary;
    }

    tr.disabled {
      td {
        color: lightgray;
      }
    }
  }

  th,
  td {
    border-top: 0;
    border-bottom: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  tfoot {
    background-color: #f4f4f4;
  }
}

// Estilos para dejar el header fijo
.fixed-header {
  overflow-y: auto;
  height: 20vh;
  min-height: 250px;
}

.fixed-header table {
  border-collapse: collapse;
  width: 100%;
}

.fixed-header th {
  position: sticky;
  top: 0;
  background-color: $secondary;
  z-index: 2;
}

.fixed-header th:first-child {
  border-top-left-radius: 4px;
}

.fixed-header th:last-child {
  border-top-right-radius: 4px;
}

.full-viewport-height {
  height: calc(100vh - 270px);
}

@media only screen and (max-width: 575.98px) {
  .full-viewport-height {
    height: calc(100vh - 320px);
  }
}

.payment-history.full-viewport-height {
  height: calc(100vh - 577px);
}

@media only screen and (max-width: 575.98px) {
  .payment-history.full-viewport-height {
    height: calc(100vh - 627px);
  }
}
