// styles for CustomAlert component
.alert {
  font-size: 1.1rem;
  background-color: #fff;

  &.alert-error {
    color: $danger !important;
    border: 1px solid $danger;
  }

  &.alert-warning {
    color: $warning !important;
    border: 1px solid $warning;
  }

  &.alert-info {
    color: $info !important;
    border: 1px solid $info;
  }
}
