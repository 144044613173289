// styles for CustomInput component
.custom-input-label {
  position: absolute;
  padding-left: 20px;
  padding-top: 8px;
  color: #838383;
  font-size: 14px;

  &.active {
    color: #b4b4b4;
  }
}

.custom-input-border {
  width: 5px;
  height: 65px;
  border-radius: 4px;
  position: absolute;

  &.border-secondary {
    background: $secondary;
  }

  &.border-error {
    background: $red;
  }
}

.custom-input-border-adjusted {
  height: 3rem; // match "custom-input-with-placeholder" height
}

.custom-input-component {
  height: 4.64rem;
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 20px;
  color: #1e1e1e;
  font-size: 1.15rem;
  background-color: transparent !important;
  border: none;

  &.without-value {
    background-color: #f4f4f4 !important;
  }

  &.input-shadow {
    box-shadow: 0px 6px 20px rgba(#8d8d8d, 0.17);
  }

  &[type="password"] {
    font-family: "fontello", "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

    /* add spacing to better separate each image */
    letter-spacing: 0.3rem;
  }

  :focus {
    box-shadow: 0px 6px 15px rgba(23, 100, 135, 0.2);
  }
}

.custom-input-tooltip {
  position: absolute;
  padding-top: 20px;
  right: 30px;
  color: $color-light;
  svg path {
    fill: $color-light;
  }

  &:hover {
    svg path {
      fill: $primary;
    }
  }
}

.custom-input-with-placeholder {
  height: 3rem;
  padding-top: 0;
}
