table.table.custom-table-info {
  font-size: 1rem;
  color: $color-light;
  margin-bottom: 0;

  th {
    color: $color-light;
    font-weight: normal;
    white-space: nowrap;
  }

  td {
    color: $primary;
    font-weight: bold;
  }

  th,
  td {
    padding: 0.4rem;
    border: 0;
  }
}
