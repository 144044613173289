// File to override bootstrap variables
// https://getbootstrap.com/docs/4.5/getting-started/theming/?#variable-defaults
$body-bg: #f0f0f0;
$body-color: #838383;

// colores genéricos de Global 3000
$primary: #0056a3;
$secondary: #3a81c0;
$tertiary: #def2f0;

$red: #af2d2d;
$green: #41b567;

$color-dark: #1e1e1e; // para texto input activo y tabla
$color-medium: #5a5a5a; // para títulos
$color-light: #838383; // textos de cuerpo, bajada, parrafos

$info: #09bfea;