// styles for CustomButton component
.custom-button {
  border-radius: 0.3rem;
  width: 100%;
  min-height: 65px;

  .spin {
    width: 2rem;
    height: 2rem;
  }

  span {
    font-size: 1.25rem;
    font-weight: 700;
  }
  &.btn.btn-primary {
    &:hover {
      box-shadow: 0px 0px 20px lighten($color: $primary, $amount: 45%);
      background-color: $primary;
      border-color: transparent;
    }
  }
  &.btn-dark.btn-block {
    &.disabled {
      opacity: 1;
      background-color: $color-medium;
    }
  }

  &.btn-outline-secondary:hover {
    color: #f4f4f4;
  }

  &.btn.btn-primary,
  &.btn.btn-block {
    .icon-btn {
      path {
        fill: $primary;
      }
    }
    &:hover {
      path {
        fill: #f4f4f4;
      }
    }
  }
}
.gateway-modal {
  .gateway-method-button {
    span {
      font-size: 14px;
      font-weight: 5600;
      color: #1e1e1e;
      flex: 1;
      text-align: left;
      padding-left: 10px;
    }
  }
  .gateway-method-button:hover,
  .gateway-method-button:focus,
  .gateway-method-button:active {
    background-color: transparent;
    box-shadow: none;
  }
}

.custom-button.gateway-method-row {
  span {
    font-size: 14px;
    font-weight: 5600;
    color: #1e1e1e;
    flex: 1;
    text-align: left;
    padding-left: 10px;
  }
}
.custom-button.gateway-method-row:hover {
  background-color: transparent;
  span {
    color: #1e1e1e;
  }
}

.btn-sm {
  min-height: 38px;
  .spin {
    width: 1.4rem;
    height: 1.4rem;
  }
}

.btn-xs {
  min-height: 36px;
  padding-left: 15px;
  padding-right: 15px;

  span {
    font-size: 0.875rem;
    font-weight: 400;
  }
}
