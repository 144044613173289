// styles for CustomToastContainer component
@import "react-toastify/dist/ReactToastify.css";

.Toastify__toast {
  border-radius: 0.3rem;
  font: normal normal normal 16px/25px Source Sans Pro, sans-serif;
  padding: 15px;
  padding-right: 20;
  margin-bottom: 1rem;
}

.Toastify__toast-container {
  width: 470px;
}
@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: calc(100vw - 2rem);
    margin: 1rem;
  }
}

.Toastify__toast--error {
  background-color: $red;
}

.Toastify__toast--success {
  background-color: $green;
}

.Toastify__toast-body .CustomToast__content {
  display: flex;
}

.Toastify__toast-body .CustomToast__icon {
  padding-right: 15px;
}

.Toastify__toast-body .CustomToast__icon .check-circle {
  fill: #fff;
}
